.wrapper {
  @apply mt-auto;

  max-height: calc(100% - 5rem);
}

.container {
  @apply flex items-center flex-col mx-auto bg-floor-white rounded-t-2xl;
}

.header {
  @apply flex w-full sticky p-2;

  svg {
    @apply hidden;
  }

  button {
    &::before {
      --tw-translate-x: -50%;

      @apply absolute left-2/4 transform mx-auto h-1 w-full rounded-full bg-floor-black bg-opacity-25;

      content: '';
      max-width: 12rem;
    }
  }
}

@screen sm {
  .wrapper {
    @apply my-auto;
  }

  .container {
    @apply rounded-2xl;

    max-width: 50rem;
  }

  .header {
    svg {
      @apply block w-10 h-auto text-floor-green;
    }

    button {
      @apply flex items-center justify-center w-14 h-14 rounded-full bg-opacity-10 bg-floor-black;

      &::before {
        @apply hidden;
      }
    }
  }
}
