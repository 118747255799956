@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Spacing */

  .spacing {
    @apply spacing-x spacing-y;
  }

  .spacing-l {
    padding-left: var(--container-spacing);
  }

  .spacing-r {
    padding-right: var(--container-spacing);
  }

  .spacing-x {
    @apply spacing-l spacing-r;
  }

  .spacing-y {
    padding-top: var(--container-spacing);
    padding-bottom: var(--container-spacing);
  }

  /* Layer */

  .bowl {
    @apply w-full spacing-x;
  }

  .content {
    @apply w-full mx-auto;

    max-width: var(--content-max-width);
  }

  .bowl-content {
    @apply bowl mx-auto;

    max-width: calc(var(--content-max-width) + (var(--container-spacing) * 2));
  }

  .bowl-content-header {
    height: 500px;
    position: relative;

    @media (max-width: 625px) {
      height: 400px;
    }
  }

  /* Helpers */

  .text-gradient {
    @apply bg-full bg-white bg-clip-text;

    -ms-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  /* Scrollbar */

  .pretty-scrollbar::-webkit-scrollbar {
    width: 1.2rem;
  }

  .pretty-scrollbar::-webkit-scrollbar-corner,
  .pretty-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .pretty-scrollbar::-webkit-scrollbar-thumb {
    min-height: 4rem;
    background-color: #c9c9c9;
  }

  .pretty-scrollbar::-webkit-scrollbar-thumb,
  .pretty-scrollbar::-webkit-scrollbar-track {
    @apply rounded-full;

    border: 0.26rem solid transparent;
    background-clip: padding-box;
  }
}
