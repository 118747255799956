.wrapper {
  @apply relative text-typography-white bg-floor-white border-b border-floor-dark border-opacity-10;

  height: var(--header-height);

  &:global(.dropdown-visible) {
    @apply rounded-b-none;
  }
}

.dropdown {
  --tw-backdrop-filter: blur(0.28rem);

  @apply fixed w-full backdrop-filter border-t border-opacity-20 bg-opacity-95 border-floor-white bg-floor-dark-700;

  height: calc(100% - var(--header-height));
}
