html,
body {
  font-display: optional;
}

body {
  @apply break-words antialiased text-typography-black bg-floor-white;

  text-rendering: optimizeLegibility;
}

img {
  max-width: 100%;
}

a,
input,
button,
textarea {
  color: currentColor;
}

/* Scrollbar */

body::-webkit-scrollbar {
  width: 1.2rem;
}

body::-webkit-scrollbar-corner,
body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  min-height: 4rem;
  background-color: #c9c9c9;
}

body::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-track {
  @apply rounded-full;

  border: 0.26rem solid transparent;
  background-clip: padding-box;
}
