.react-paginate {
  @apply flex flex-row list-none gap-4 items-center;
}

.react-paginate > li {
  @apply flex;
}

.react-paginate > li > a {
  @apply flex items-center font-bold justify-center py-2 px-4 rounded-md border border-opacity-25 bg-opacity-20 bg-floor-green border-floor-green text-typography-green;
}

.react-paginate-link-active > a {
  @apply bg-floor-green text-typography-white !important;
}

.react-paginate > li.previous,
.react-paginate > li.next {
  &.react-paginate-link-disabled {
    @apply hidden invisible;
  }
}

.react-paginate-link-disabled a {
  color: rgb(198, 197, 202);
  border: 0.1rem solid rgb(198, 197, 202);
}
