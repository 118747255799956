:root {
  --header-height: 60px;

  --container-spacing: 1rem;
  --content-max-width: 1440px;
}

@screen sm {
  :root {
    --container-spacing: 2rem;
  }
}

@screen md {
  :root {
    --container-spacing: 2.5rem;
  }
}

@screen lg {
  :root {
    --container-spacing: 3rem;
    --header-height: 80px;
  }
}

@screen xl {
  :root {
    --container-spacing: 4rem;
  }
}
